import * as React from "react";
import { Link, Script } from "gatsby";

const menu = [
    {
        link_url: "/",
        link_title: "Podcast",
        active: false,
    },
    {
        link_url: "/archive",
        link_title: "Blog",
        active: true,
    },
    {
        link_url: "/mission",
        link_title: "Mission",
        active: false
    }
];

export const Layout = ({ children }) => {
    return (
        <div className="flex flex-col min-h-screen">
            <header>
                <div className="container mx-auto">
                    <div className="flex content-center items-center flex-wrap lg:flex-no-wrap">
                        <div className="flex-1 lg:flex-none order-1 font-bold font-sans inline-block px-3 py-1">
                            <Link
                                to="/"
                                className="flex transition-all ease-in-out duration-300 text-coral-600 hover:text-coral-800 content-center items-center py-2"
                            >
                                <span className="text-sm lg:text-base">Without Boxes</span>
                            </Link>
                        </div>
                        <div className="order-3 lg:flex-1 flex justify-end font-sans font-light ps-4">
                            {menu.map(item => (
                                <Link
                                    key={item.link_url}
                                    to={item.link_url}
                                    title=""
                                    className="transition-all ease-in-out duration-300 mr-5 hover:text-zinc-600"
                                    activeClassName="font-extrabold text-coral-800"
                                    // getProps={(loc) => { isPost(loc) }}
                                    partiallyActive={item.active}
                                >
                                    {item.link_title}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </header>
            <main className="flex-grow z-0">{children} </main>{" "}
            <footer className="mt-10 text-center py-4">
                <div className="container mx-auto font-sans px-3 2xl:px-0">
                    <p className="text-xs	text-gray-500">
                        All Rights Reserved © 2012-{new Date().getFullYear()} <a href="https://www.annedorko.com" target="_blank" rel="noreferrer">
                            Anne Dorko
                        </a>
                        <br />
                        In association with{" "}
                        <a href="https://dorko.tv" target="_blank" rel="noreferrer">
                            Dorko.tv
                        </a>
                    </p>
                </div>
            </footer>{" "}
            {(process.env.NODE_ENV === 'production') && <Script defer data-domain="withoutboxes.com" src="https://web.dorko.tv/js/script.js"></Script>}
        </div>
    );
};

export default Layout;
