import * as React from "react"
import { Layout } from "../templates/layout";


const IndexPage = () => {
  return (
    <Layout>
      <main>
        <div className="pt-10 text-center">
          <h1 className="font-extrabold text-5xl md:text-6xl leading-tighter tracking-tight mb-2">Without Boxes</h1>
          <p className="font-serif text-xl text-olive-400">Ordinary people. Extraordinary stories.</p>
          <p className="mt-4">Our mission.</p>
        </div>
        <div className="mt-10 max-w-3xl mx-auto text-lg px-6 lg:px-0">
          <p className="mb-4">Without Boxes is committed to equipping individuals with the resources necessary to turn obstacles into opportunities.</p>

          <p className="mb-4">Millions of people are out there chasing passions, starting projects, and saying no to the status quo. These people are our neighbors, our friends, and our family. They’re ordinary people doing extraordinary things. The Without Boxes podcast enables folks to share their stories and advice, helping listeners from all around the world to get inspired and chase their own passions. We’re breaking barriers and inspiring dreamers, one story at a time.</p>

          <hr className="mb-4" />

          <p className="mb-4">Do you want to share your story?</p>

          <p>Send us an email introducing yourself and the story you would like to share at share@withoutboxes.com</p>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Without Boxes</title>
